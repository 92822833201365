<template>
  <div class="tooltip">
    <slot />
    <span class="tooltiptext p-4">{{message}}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: #22222269;
  min-width: 120px;
  color: #fff;
  text-align: center;
  /* opacity: 0.2; */
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: 70%;
  left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
